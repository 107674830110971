<template>
  <div class="rightLink">
    
    <div class="bottomDiv" style="margin-top:15px;">
      <div class="title">统计分析</div>
      <div class="formDiv" style="width:80vw; margin: 0 auto;">
          <el-form label-position="right" label-width="70px">
            <el-form-item label="月份:">
                  <el-date-picker
                    style="height:40px;width: 100%;"
                    v-model="value2"
                    type="month"
                    @change="changeMonth"
                    
                    placeholder="选择月">
                    
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="时间:" class="time_pick">
                  <el-date-picker
                    style="height:40px;width: 100%;"
                    v-model="FormData.orderTime"
                    type="daterange"
                    align="right"
                    range-separator=" - "
                    start-placeholder="请选择开始日期"
                    end-placeholder="请选择结束日期"
                    size="small"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </el-form-item>
                <!-- <el-form-item label="时间:" class="time_pick" label-width="70px">
                  <el-date-picker
                    style="height:40px;width: 80%;"
                    v-model="FormData.orderTime"
                    type="daterange"
                    align="right"
                    range-separator=" - "
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    size="small"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </el-form-item> -->
            
                

          </el-form>
          <div v-show="isadmin" style="display: flex; justify-content: space-between; width: 70vw;">
                <el-form style="margin-bottom: 8px !important;">
                  <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 90%; display: flex; justify-content: space-between;">
                    <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                  </el-select>
                </el-form>
                <div class="BtnSearch" style=" height: 30px; margin-top: 5px; width: 16vw" @click="searchBtn()">查询</div>

              </div>
        </div>

        <div class="card">
        <div>
          <div>
            <p class="s_title">
              营收总金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.count_money }}</p>
            <!-- <p class="s_profress">+5%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        
        <div>
          <div>
            <p class="s_title">
              临时充电金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.sum_money  }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        <div>
          <div>
            <p class="s_title">
              钱包充值金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.Balance_recharge }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        
        <div>
          <div>
            <p class="s_title p_bottom">
              临时充电退费(元)
              <!-- <el-icon><Bottom /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.Return_of_order }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        <div>
          <div>
            <p class="s_title">
              钱包余额退费(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.refund_money }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        <div>
          <div>
          
            <p class="s_title">
              钱包消费金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">0.00</p>
            <!-- <p class="s_profress">+2%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          
        </div>
        </div>
        <div>
          <div>
            <p class="s_title p_bottom">
              钱包剩余金额(元)
              <!-- <el-icon><Bottom /></el-icon> -->
            </p>
            <p class="s_water">0.00</p>
            <!-- <p class="s_profress">-0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        <div>
          
          <div>
            <p class="s_title">
              用电量(度)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.electric_quantity }}</p>
            <!-- <p class="s_profress">+5%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
        
        <div>
          <div>
            <p class="s_title">
              电动车订单量
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.type_id2  }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="70" :show-text="false" /> -->
          </div>
          
        </div>
        <div>
          <div>
            <p class="s_title p_bottom">
              电动汽车订单量
              <!-- <el-icon><Bottom /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.type_id4  }}</p>
            <!-- <p class="s_profress">-0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </div>
        </div>
       
       
        
        
      </div>
      <div class="title">营业收费管理</div>
      <div class="card1">
        <!-- <div>
          <router-link to="/revenue_manage/order_manage" >
            <img src="../../assets/img/icon_4.png" alt="" />
            <p>订单记录</p>
          </router-link>
        </div>
         -->
        <div>
          <router-link to="/mobile_index/order_refund" >
            <img src="../../assets/img/icon_2.png" alt="" />
            <p>订单退费</p>
          </router-link>
        </div>
        <div>
          <router-link to="/mobile_index/user_recharg" style="display: block;">
            <img src="../../assets/img/icon_7.png" alt="" />
            <p>余额充值</p>
          </router-link>
        </div>
        <div>
          <router-link to="/mobile_index/refund_detail" style="display: block;">
            <img src="../../assets/img/icon_3.png" alt="" />
            <p>余额退费</p>
          </router-link>
        </div>
        <div>
          <router-link to="/mobile_index/ledger_manage" style="display: block;">
            <img src="../../assets/img/icon_14.png" alt="" />
            <p>分账明细</p>
          </router-link>
        </div>
      
      </div>
    </div>
    <!-- <div class="bottomDiv" style="height: 25vh;margin-top:15px;">
      
    </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { operator_data,revenue_management } from "@/request/api";

export default {
  name: "RevenueView",
  setup() {
    
    const data = reactive({
      value2: '',
      FormData:{
        orderTime:['',''],
      },
      radio: 1,
      //表单数据
     
      tableData: [],
      loading: false,
      isadmin:false,
      users:[],
    });
    const changeMonth = (value) =>{
      let date = new Date(value);
      let month = (date.getMonth() + 1).toString().padStart(2,'0');
      let year = date.getFullYear();
      let day = new Date(year,month,0);
      data.FormData.orderTime[0] = year + '-' + month + '-01';
      data.FormData.orderTime[1] = year + '-' + month + '-' + day.getDate();
    }

    const searchBtn = () => {
      getList()
   
    }
    const formatDate = (date) => {
          var myyear = date.getFullYear();
          var mymonth = date.getMonth() + 1;
          var myweekday = date.getDate();

          if (mymonth < 10) {
              mymonth = "0" + mymonth;
          }
          if (myweekday < 10) {
              myweekday = "0" + myweekday;
          }
          return (myyear + "-" + mymonth + "-" + myweekday);
      }
    const handleEdit = () => {
      console.log("导出");
    };
    const handleSave = () => {
      console.log("打印");
    };
   
    
    const styles = `<style>
    .eltable{
      width:1000px;
    }
    </style>`;

    const getTime = () => {
        let date = new Date;
        let y = date.getFullYear().toString();
        let m = (date.getMonth() + 1).toString().padStart(2, 0);
        var curretMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var curretMonthDayCount = curretMonth.getDate();
        data.FormData.orderTime[0] = [y, m, 1].join("-")
        data.FormData.orderTime[1] = [y, m, curretMonthDayCount].join("-")
        data.value2 = [y, m].join("-")
      }
    const getList = () => {
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        start_execute_date : data.FormData.orderTime[0],
        stop_execute_date : data.FormData.orderTime[1],
        username : data.FormData.username,
        
      }
      revenue_management(dataa).then((res) => {
        console.log(res,'sssssss');
        data.FormData.type_id2 = ''?'0':res.data.type_id2?res.data.type_id2.achieve:0
          data.FormData.type_id4 = ''?'0':res.data.type_id4?res.data.type_id4.achieve:0
          data.FormData.sum_money = ''?'0':parseFloat( res.data.sum_money?res.data.sum_money.achieve:0).toFixed(2)
          data.FormData.Balance_recharge = ''?'0':parseFloat( res.data.Balance_recharge?res.data.Balance_recharge.achieve:0).toFixed(2)
          data.FormData.Return_of_order = ''?'0':parseFloat( res.data.Return_of_order?res.data.Return_of_order.achieve:0).toFixed(2)
          data.FormData.refund_money = ''?'0':parseFloat( res.data.refund_money.achieve?res.data.refund_money.achieve:0).toFixed(2)
          data.FormData.refill_card  = ''?'0':parseFloat( res.data.refill_card?res.data.refill_card.achieve:0).toFixed(2)
          data.FormData.count_money  = ''?'0':parseFloat( res.data.count_money?res.data.count_money.achieve:0).toFixed(2)
          data.FormData.electric_quantity  = ''?'0':parseFloat( res.data.electric_quantity?res.data.electric_quantity .achieve:0).toFixed(2)
        
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem('username')
      const users1 = []
      operator_data().then((res) => {
        console.log(res,'sssssss');
        data.users = res
        res.forEach((a)=>{    
          users1.push(a.username)
      })
      if(users1.indexOf(username1)==-1){
        data.isadmin = true
      }else{
        data.isadmin = false
        data.FormData.username = username1
      }
      });
    };
    const zforder = () => {
      router.push({
        path: '/order_manage/order_history',
          query: {
            zftype: 'zhif'
          }
      })
    }

    const yeorder = () => {
      router.push({
        path: '/order_manage/order_history',
          query: {
            zftype: 'yue'
          }
      })
    }

    onMounted(() => {
      getList();
      getTime()
      getUsername()
    });
    return {
      ...toRefs(data),
      changeMonth,
      searchBtn,
      handleEdit,
      handleSave,
      getList,
      // getTime,
      getUsername,
      formatDate,
      zforder,
      yeorder
    };
  },
};
</script>
<style scoped>
.eltable {
  width: 90%;
  margin: 5px auto;
  height: 240px;
  overflow-y: auto;
}
.formDiv {
  width: 1000px;
  margin: 20px auto 0;
}
.bottomDiv {
  min-height: 240px;
}
.card {
  display: flex;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  
}
.card>div{
  width: 30% !important;
  height: 40px;
  margin: 4px auto !important;
}
.card1 {
  display: flex;
  width: 96%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  
}
.card1 p{
  color: black !important;
  
}
.s_title {
  /* font-size: 19rpx; */
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.card > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  /* height: 200px; */
}
.card > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.card1 > div {
  width: 22%;
  margin: 20px auto;
  /* border: 1px solid red; */
  /* padding: 10px; */
  /* height: 200px; */
}
.card1 p {
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
}
.card1 > div img {
  margin: 0 15%;
  width: 50%;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  /* margin-left: -100px; */
}
</style>
